html {
	font-family: sans-serif;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	text-size-adjust: none;
}

body {
	margin: 0;
}

a {
	background: transparent;
}

h1 {
	margin: 0;
}

img {
	border: 0;
}

svg:not(:root) {
	overflow: hidden;
}

fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0;
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	margin: 0;
}

button,
input {
	line-height: normal;
}

button,
select {
	text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
@font-face {
	font-family: "Wacky Action BTN";
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: url(/assets/fonts/wacky-action-btn.woff2) format("woff2"),
		 url(/assets/fonts/wacky-action-btn.woff) format("woff");
}

@font-face {
	font-family: "Museo Sans";
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: url(/assets/fonts/museo-sans-500.woff2) format("woff2"),
		 url(/assets/fonts/museo-sans-500.woff) format("woff");
}
.menu {
	display: none;
	position: absolute;
	right: 17px;
	right: calc(17px + env(safe-area-inset-right));
	top: 17px;
	top: calc(17px + env(safe-area-inset-top));
	min-width: 94px;
	text-align: center;
	user-select: none;
	-webkit-touch-callout: none;
	touch-callout: none;
	-webkit-tap-highlight-color: transparent;
	tap-highlight-color: transparent;
}

.menu h2 {
	font-size: 15px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0;
	max-width: 130px;
	pointer-events: none;
	user-select: none;
}

.menu input,
.menu label {
	cursor: pointer;
	font-size: 15px;
}

.menu.hidden {
	height: 0;
}

.menu fieldset {
	border: none;
}

.menu.hidden fieldset {
	height: 0;
}

.range {
	width: 130px;
	height: 44px;
	padding-top: 2px;
	opacity: 0.6;
	cursor: col-resize;
	transition: opacity 0.15s;
	will-change: opacity;
}

body:not(.mobile) .range:hover,
.range:active {
	opacity: 1;
}

.range .track {
	position: relative;
	width: 100%;
	height: 1px;
	top: 16px;
	background-color: #fff;
	pointer-events: none;
}

.range .thumb {
	position: relative;
	width: 16px;
	height: 16px;
	top: 7px;
	border-radius: 50%;
	background-color: #fff;
	pointer-events: none;
	transform: translateZ(0);
	will-change: transform;
}

.item .list input[type="radio"] {
	-webkit-appearance: none;
	display: none;
}

.item .list label {
	display: block;
	border: none;
	margin: 0 auto;
	line-height: 1.6;
	width: 90px;
	opacity: 0.5;
	transition: opacity 0.15s;
	will-change: opacity;
}

.item .list label:hover {
	opacity: 1;
}

.item .list label:active {
	background-color: rgba(255, 255, 255, 0.25);
	border-radius: 4px;
}

.item .list input:checked + label {
	opacity: 1;
}

.item.noise .list {
	margin-top: 10px;
	transition: opacity 0.15s;
}

.checkbox input {
	display: none;
}

.checkbox {
	margin: 8px auto 0 auto;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.checkbox label {
	position: absolute;
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-image: url(/assets/images/circle.svg);
	background-size: 30px 30px;
	opacity: 0.6;
	transition: opacity 0.15s;
	will-change: opacity;
}

body:not(.mobile) .checkbox label:hover,
.checkbox label:active {
	opacity: 1;
}

.checkbox label:active {
	background-color: rgba(255, 255, 255, 0.25);
	background-image: none;
}

/* Check inside checkbox */
.checkbox label:after {
	content: "";
	position: absolute;
	width: 14px;
	height: 6px;
	left: 7px;
	top: 10px;
	border: 2px solid #fff;
	border-top: none;
	border-right: none;
	transform: rotate(-45deg);
	opacity: 0;
	will-change: transform, opacity;
}

body:not(.mobile) .checkbox label:hover:after,
.checkbox label:active:after {
	border-color: transparent;
	opacity: 1;
}

.checkbox label:active:after {
	border-color: #fff;
	opacity: 1;
}

.checkbox input[type="checkbox"]:checked + label:after {
	border-color: #fff;
	opacity: 1;
}

.item.mic,
.item.elasticity,
.item.total,
.item.mode,
.item.noise {
	position: relative;
	right: 0;
	transform: translateZ(0);
	transition: transform 0.4s cubic-bezier(1, 0, 0.4, 1.5);
	will-change: transform;
}

.menu.hidden .item.mic,
.menu.hidden .item.elasticity,
.menu.hidden .item.total,
.menu.hidden .item.mode,
.menu.hidden .item.noise {
	transform: translateX(160px);
}

.item {
	margin-bottom: 8px;
}

.item.mic .icon {
	width: 80px;
	height: 80px;
	margin: 0 auto;
	padding: 0;
	border-radius: 50%;
	background-color: transparent;
	background-size: 92% 92%;
	background-repeat: no-repeat;
	background-position: 50% 38%;
	cursor: pointer;
	user-select: none;
	opacity: 0.5;
	transition: opacity 0.15s;
	will-change: opacity;
}

body:not(.mobile) .item.mic .icon:hover,
.item.mic .icon:active {
	opacity: 1;
}

.item.mic .icon:active {
	background-color: rgba(255, 255, 255, 0.25);
}

.item.mic .icon.on {
	background-image: url(/assets/images/microphone-on.svg);
}

.item.mic .icon.off {
	background-image: url(/assets/images/microphone-off.svg);
}

.item.mic {
	/*border: 1px solid red;*/
	margin-bottom: 20px;
}

.item.elasticity {
	/*border: 1px solid blue;*/
	transition-delay: 0.05s;
}

html[lang="de"] .item.item.elasticity h2 {
	letter-spacing: -0.2px;
}

.item.total {
	/*border: 1px solid green;*/
	transition-delay: 0.1s;
}

.item.mode {
	/*border: 1px solid yellow;*/
	transition-delay: 0.15s;
	margin-bottom: 25px;
}

.item.mode h2 {
	margin-bottom: 5px;
}

.item.noise {
	/*border: 1px solid orange;*/
	transition-delay: 0.2s;
}

.noise-alert {
	opacity: 0;
	cursor: default;
	user-select: none;
	user-drag: none;
	pointer-events: none;
	will-change: opacity;
}

.noise-alert h3 {
	position: absolute;
	width: 100%;
	padding: 0;
	top: 50%;
	margin-top: -200px;
	font-family: "Wacky Action BTN", "Comic Sans MS", sans-serif;
	font-size: 120px;
	font-weight: normal;
	line-height: 1;
	text-rendering: optimizeLegibility;
	text-align: center;
	text-shadow: 0 0 16px #000;
	user-select: none;
	user-drag: none;
	pointer-events: none;
	white-space: nowrap;
	text-transform: uppercase;
	animation: wobble-anim 0.8s ease-in-out infinite;
}

@keyframes wobble-anim {
	0% {
		transform: translateZ(0);
	}

	50% {
		transform: scaleX(0.9) scaleY(1.11);
	}

	100% {
		transform: translateZ(0);
	}
}
.intro-main {
	will-change: opacity;
	opacity: 0;
	display: none;
}

.intro-main h1 {
	position: absolute;
	width: 100%;
	padding: 0;
	top: 50%;
	margin-top: -150px;
	font-family: "Wacky Action BTN", "Comic Sans MS", sans-serif;
	font-size: 120px;
	font-weight: normal;
	line-height: 1;
	text-rendering: optimizeLegibility;
	text-align: center;
	text-shadow: 0 0 16px rgba(0, 0, 0, 0.75);
	user-select: none;
	user-drag: none;
	white-space: nowrap;
}

html[lang="zh-CN"] .intro-main h1 {
	font-weight: bold;
}

.intro-main .button {
	position: absolute;
	width: 100%;
	top: 50%;
	margin-top: 100px;
	text-align: center;
}

.intro-main .button p {
	display: inline;
	border-radius: 18px;
	padding: 16px 24px 15px 24px;
	font-size: 20px;
	text-transform: uppercase;
	cursor: pointer;
	user-select: none;
	color: #fff;
	background-color: rgba(255, 255, 255, 0.25);
	transition: background-color 0.1s ease-out;
}

.mobile .intro-main .button {
	transition: none;
}

.intro-main .button p:hover {
	background-color: #90f;
}

.intro-main .button p:active {
	background-color: #f09;
}

.intro-main.emoji .button p:active {
	background-color: #eb3;
}

.intro-main.numbers .button p:active {
	background-color: #14f;
}

.intro-main.bubbles .button p:hover {
	background-color: #26d;
}

.intro-main.bubbles .button p:active {
	background-color: #6af;
}

.intro-main.eyes .button p:hover {
	background-color: #812;
}

.intro-main.eyes .button p:active {
	background-color: #c12;
}

.intro-main .privacy {
	position: absolute;
	bottom: 0;
	bottom: env(safe-area-inset-bottom);
	left: 50%;
	width: 300px;
	margin-left: -150px;
	font-size: 15px;
	text-align: center;
	text-shadow: 0 0 2px #000;
}

.intro-main .privacy a {
	color: #fff;
	text-decoration: none;
}

.intro-main .privacy a:hover {
	text-decoration: underline;
}

.intro-text {
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -20px;
	text-align: center;
	z-index: 2;
	will-change: opacity;
	opacity: 0;
}

.intro-text p {
	font-size: 20px;
	line-height: 1.6;
	margin: 0;
}

.intro-text a {
	color: #aaa;
	text-decoration: underline;
}

.intro-text a:hover {
	color: #fff;
}

.intro-text a:active {
	color: #90f;
}

.intro-text.error {
	display: none;
	margin-top: -35px;
}

.intro-text.noscript {
	opacity: 1;
}

.intro-text .description {
	display: none;
}
.ball {
	position: absolute;
	border-radius: 50%;
	will-change: transform;
	transform: translateZ(0);
	backface-visibility: hidden;
	user-select: none;
	user-drag: none;
}

.grab .ball {
	cursor: pointer;
	cursor: grab;
}

.grab .ball:active {
	cursor: grabbing;
}


/* Plastic */
.bg.plastic {
	background-color: #223340;
	background-image: linear-gradient(
		to bottom,
		#223340 0%,
		#000 90%
	);
}

.plastic .ball {
	background-image: radial-gradient(
		circle closest-side at 50% 60%,
		rgba(255, 255, 255, 0.2) 0%,
		rgba(255, 255, 255, 0.2) 30%,
		rgba(255, 255, 255, 0.1) 80%,
		rgba(0, 0, 0, 0.2) 130%
	);
}

.plastic .ball:after {
	content: "";
	position: absolute;
	left: 19%;
	top: 2%;
	width: 62%;
	height: 44%;
	border-radius: 50%;
	background-image: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.7) 0%,
		rgba(255, 255, 255, 0.1) 100%
	);
}

.plastic .ball.white {
	background-color: #fff;
}

.plastic .ball.purple {
	background-color: #90f;
}

.plastic .ball.yellow {
	background-color: #fb0;
}

.plastic .ball.pink {
	background-color: #f09;
}

.plastic .ball.green {
	background-color: #3d0;
}

.plastic .ball.red {
	background-color: #f00;
}

.plastic .ball.orange {
	background-color: #f50;
}

.plastic .ball.blue {
	background-color: #03f;
}

.plastic .ball.cyan {
	background-color: #0ee;
}

.plastic .ball.green {
	background-color: #3d0;
}

.plastic .ball.black {
	background-color: #445;
}


/* Emoji */
.bg.emoji {
	background-color: #306;
	background-image: linear-gradient(
		to bottom,
		#306 0%,
		#102 90%
	);
}

.emoji .ball {
	border-radius: 0;
	/*background-color: #fc3;*/
	/*background-image: url(/assets/images/emoji.jpg);*/
	background-image: url(/assets/images/emoji-new.png);
	background-size: 600% 600%;
	/*background-position: 0 20%;*/
}


/* Numbers */
.bg.numbers {
	background-color: #214621;
	background-image: linear-gradient(
		to bottom,
		#135 0%,
		#012 90%
	);
}

.numbers .ball {
	background-image: radial-gradient(
		circle closest-side at 50% 60%,
		rgba(255, 255, 255, 0.2) 0%,
		rgba(255, 255, 255, 0.2) 30%,
		rgba(255, 255, 255, 0.1) 80%,
		rgba(0, 0, 0, 0.2) 130%
	);
}

.numbers .ball:after {
	content: "";
	position: absolute;
	left: 19%;
	top: 2%;
	width: 62%;
	height: 44%;
	border-radius: 50%;
	background-image: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0.6) 0%,
		rgba(255, 255, 255, 0.1) 100%
	);
}

.numbers .number {
	content: "";
	position: absolute;
	width: 100%;
	top: 50%;
	font-family: "Wacky Action BTN", "Comic Sans MS", sans-serif;
	font-size: 32px;
	margin-top: -20px;
	text-align: center;
	pointer-events: none;
}

.numbers .ball {
	text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.numbers .ball.n1 {
	background-color: #f00;
}

.numbers .ball.n2 {
	background-color: #14f;
}

.numbers .ball.n3 {
	background-color: #ea0;
}

.numbers .ball.n4 {
	background-color: #3c0;
}

.numbers .ball.n5 {
	background-color: #632;
}

.numbers .ball.n6 {
	background-color: #f4a;
}

.numbers .ball.n7 {
	background-color: #90f;
}

.numbers .ball.n8 {
	background-color: #f60;
}

.numbers .ball.n9 {
	background-color: #0cd;
}

.numbers .ball.n10 {
	letter-spacing: -1px;
	color: #334;
	background-color: #fff;
	text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}


/* Bubbles */
/*@keyframes underwater-anim {
	to {
		background-position: 1200px 0;
	}
}*/

.bg.bubbles {
	background-color: #020c55;
	background-image: url(/assets/images/underwater.jpg);
	background-size: 1200px 700px;
	background-position: 50% 0;
	background-repeat: repeat-x;
	/*animation: underwater-anim 20s infinite linear;*/
}

.bubbles .ball {
	box-shadow: inset 0px 10px 30px 5px rgba(150, 200, 255, 0.5);
 	background-image: radial-gradient(
		circle farthest-corner at 50% 60%,
		rgba(100, 150, 255, 0.1) 0%,
		rgba(100, 150, 255, 0.2) 40%,
		rgba(100, 150, 255, 0.3) 55%
	);
}

.bubbles .ball:after {
	content: "";
	position: absolute;
	top: 4%;
	left: 10%;
	width: 80%;
	height: 80%;
	border-radius: 100%;
	background-image: radial-gradient(
		circle farthest-corner at 50% 80%,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0) 74%,
		#ffffff 80%,
		#ffffff 84%,
		rgba(255, 255, 255, 0) 100%
	);
}

.bubbles.grab .ball:active,
.bubbles.grab .ball {
	cursor: pointer;
	cursor: crosshair;
}


/* Eyeballs */
.bg.eyes {
	background-color: #444;
	background-image: linear-gradient(
		to bottom,
		#722 0%,
		#211 90%
	);
}

.eyes .ball {
	background-color: #fcfcfc;
	background-image: radial-gradient(
		circle farthest-corner at 50% 50%,
		#fcfcfc 0%,
		#f9eff0 62%,
		#944 100%
	);
}

@keyframes eye-anim {
	0% {
		transform: translateZ(0);
	}

	10%, 35% {
		transform: translateX(-40%) translateY(40%) skewX(15deg) skewY(-10deg) scale(0.95);
	}

	40%, 50% {
		transform: translateZ(0);
	}

	65%, 85% {
		transform: translateX(40%) translateY(-40%) skewX(5deg) skewY(2deg) scaleX(0.95);
	}

	90%, 100% {
		transform: translateZ(0);
	}
}

.eyes .ball .outer-iris {
	position: absolute;
	width: 48%;
	height: 48%;
	margin: 26%;
	border-radius: 50%;
	will-change: transform;
	transform: translateZ(0);
	animation: eye-anim 6s ease infinite;
	pointer-events: none;
}

.eyes .ball .iris {
	position: relative;
	margin: 3%;
	width: 94%;
	height: 94%;
	border-radius: 50%;
}

.eyes .ball .iris:before {
	content: "";
	display: block;
	position: absolute;
	width: 37.5%;
	height: 37.5%;
	border-radius: 50%;
	top: 31.25%;
	left: 31.25%;
	background-color: #000;
}

.eyes .ball .iris:after {
	content: "";
	display: block;
	position: absolute;
	width: 31.25%;
	height: 31.25%;
	border-radius: 50%;
	top: 18.75%;
	left: 18.75%;
	background-color: rgba(255, 255, 255, 0.2);
}

.eyes .ball.blue .iris {
	background-color: #5af;
	background-image: radial-gradient(
		circle farthest-corner at 50% 50%,
		#5af 30%,
		#36b 100%
	);
}

.eyes .ball.blue .outer-iris {
	animation-delay: 0.5s;
}

.eyes .ball.green .iris {
	background-color: #ad6;
	background-image: radial-gradient(
		circle farthest-corner at 50% 50%,
		#ad6 30%,
		#483 100%
	);
}

.eyes .ball.green .outer-iris {
	animation-delay: 1s;
}

.eyes .ball.brown .iris {
	background-color: #a64;
	background-image: radial-gradient(
		circle farthest-corner at 50% 50%,
		#a64 30%,
		#721 100%
	);
}

.eyes .ball.green .outer-iris {
	animation-delay: 1.5s;
}

.eyes .ball.gray .iris {
	background-color: #ccc;
	background-image: radial-gradient(
		circle farthest-corner at 50% 50%,
		#ccc 30%,
		#888 100%
	);
}

.eyes .ball.gray .outer-iris {
	animation-delay: 2s;
}

.eyes .ball.pink .iris {
	background-color: #f5a;
	background-image: radial-gradient(
		circle farthest-corner at 50% 50%,
		#f39 30%,
		#935 100%
	);
}

.eyes .ball.pink .outer-iris {
	animation-delay: 2.5s;
}

.eyes .ball.purple .iris {
	background-color: #a5f;
	background-image: radial-gradient(
		circle farthest-corner at 50% 50%,
		#a5f 30%,
		#427 100%
	);
}

.eyes .ball.purple .outer-iris {
	animation-delay: 3s;
}

.eyes .ball.yellow .iris {
	background-color: #fc3;
	background-image: radial-gradient(
		circle farthest-corner at 50% 50%,
		#fc3 30%,
		#c80 100%
	);
}

.eyes .ball.yellow .outer-iris {
	animation-delay: 3.5s;
}
html {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

body {
	width: 100%;
	height: 100%;
	overflow: hidden;
	font-family: "Museo Sans", sans-serif;
	font-weight: 500;
	line-height: 1.6;
	-webkit-font-smoothing: antialiased;
	color: #fff;
	background-color: #000;
}

::scrollbar {
    display: none;
}

.drop {
	transition: top 0.4s ease-in, opacity 0.3s ease;
}

.fade {
	transition: opacity 0.3s ease 0.3s;
}

.bg {
	position: absolute;
	width: 100%;
	height: 100%;
}

.stage.fade,
.bg.fade {
	transition: opacity 0.4s ease;
}

.stage {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.mobile .stage {
	height: auto;
	top: 0;
	bottom: 0;
	bottom: env(safe-area-inset-bottom);
	overflow: visible;
}

.stage:active.grab {
	cursor: pointer;
	cursor: move;
}

.awake {
	position: absolute;
	width: 0;
	height: 0;
}
@media (max-width: 1000px) {
	.noise-alert h3 {
		font-size: 80px;
		margin-top: -180px;
	}
}

@media (max-width: 800px) {
	.intro-main h1 {
		margin-top: -140px;
	}

	.intro-main h1 {
		font-size: 96px;
	}

	.intro-main .button p,
	.intro-text p {
		font-size: 19px;
	}

	.intro-main .button {
		margin-top: 90px;
	}
}

@media (max-width: 600px), (max-height: 640px) {
	.menu {
		right: 10px;
		right: calc(10px + env(safe-area-inset-right));
		top: 10px;
		top: calc(10px + env(safe-area-inset-top));
	}

	.menu h2 {
		max-width: 100px;
	}

	.range,
	.item.mode label {
		margin: 0 auto;
		width: 100px;
	}

	.range {
		padding-top: 0;
	}

	.menu h2,
	.menu label {
		font-size: 13px;
	}

	.item.mic .icon {
		width: 60px;
		height: 60px;
	}

	.item.mode h2 {
		margin-bottom: 8px;
	}

	.item.mode label {
		line-height: 1.7;
	}

	html[lang="de"] .item.item.elasticity h2 {
		letter-spacing: -1px;
	}

	.checkbox {
		margin-top: 5px;
	}

	.bg.bubbles {
		background-size: 600px 350px;
	}
}

@media (max-height: 560px) {
	.menu h2,
	.menu label {
		font-size: 11.5px;
	}

	.range,
	.item.mode label {
		margin: 0 auto;
		width: 80px;
	}

	.item.mode label {
		line-height: 1.6;
	}

	.item {
		margin-bottom: 0;
	}

	.item.mic {
		margin-bottom: 10px;
	}

	.item.mic .icon {
		width: 48px;
		height: 48px;
	}

	.item.total {
		transition-delay: 0.1s;
	}

	.item.mode {
		transition-delay: 0.2s;
		margin-bottom: 15px;
	}

	.item.noise {
		transition-delay: 0.3s;
	}

	.range {
		height: 40px;
	}

	.noise-alert h3 {
		margin-top: -120px;
	}
}

@media (max-width: 600px), (max-height: 480px) {
	.noise-alert,
	.item.noise {
		display: none;
	}

	.intro-main h1 {
		font-size: 80px;
		line-height: 0.9;
		margin: -175px auto 0 auto;
		min-width: 0;
		white-space: pre-wrap;
		text-shadow: 0 0 8px #000, 0 0 32px #000;
	}

	.intro-main h1 span {
		display: block;
		max-width: 320px;
		margin: 0 auto;
	}

	.intro-text {
		margin-top: -15px;
		pointer-events: none;
	}

	.intro-text.error {
		display: none;
		min-width: 0;
		margin-top: -80px;
		pointer-events: auto;
	}

	.intro-text.error p {
		max-width: 280px;
		margin: 0 auto;
		padding: 10px 20px;
	}

	.intro-text p {
		line-height: 1.3;
		text-shadow: 0 0 1px #000, 0 0 8px #000, 0 0 32px #000;
		max-width: 310px;
		margin: 0 auto 10px auto;
	}

	.intro-main .button {
		margin-top: 125px;
	}

	.intro-main .button p {
		background-color: #90f;
	}

	.intro-main.bubbles .button p {
		background-color: #26d;
	}

	.intro-main.eyes .button p {
		background-color: #812;
	}

	.intro-text p,
	.intro-main .button p {
		font-size: 17px;
	}

	.intro-main .privacy {
		font-size: 13px;
	}

	.stage:not(.grab) {
		opacity: 0.4;
	}
}

@media (max-height: 480px) {
	.intro-main .privacy {
		display: none;
	}
}

@media (max-height: 320px) {
	.item.mic,
	.item.elasticity {
		display: none;
	}

	.item.total {
		transition-delay: 0.1s;
	}

	.item.mode {
		transition-delay: 0.2s;
	}
}

@media (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
	.emoji .ball {
		/*background-image: url(/assets/images/emoji-2x.jpg);*/
		background-image: url(/assets/images/emoji-new-2x.png);
	}
}
